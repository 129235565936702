<template>
  <section class="views">
    <!-- header -->
    <Headers @viewUploadVideo="datumZZC = true"></Headers>
    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner_team.jpg')"
      />
      <div class="posters_ewm">
        <div class="ewm" @click="whatapp()">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/hodgepodge/ewm.png')" alt="" />
            </div>
            <div class="ewm3_value">
              <img :src="require('@/assets/image/wa.png')" alt="" /> 人工諮詢
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 举办方 -->
    <div class="organizers">
      <div class="box_uls">
        <div class="box_title">
          <img
            :src="require('@/assets/image/hodgepodge/pctitle1.png')"
            alt=""
          />
        </div>
        <div class="box_centent zhuban">
          <div class="xiaoIconBox">
            <div class="xiaoIconList">
              <img :src="require('@/assets/image/xiao/xiao2.png')" alt="" />
            </div>
            <div class="xiaoIconList">
              <img :src="require('@/assets/image/xiao/xiao1.png')" alt="" />
            </div>
            <div class="xiaoIconList">
              <img :src="require('@/assets/image/xiao/xiao4.png')" alt="" />
            </div>
            <div class="xiaoIconList" style="margin-right: 30px">
              <img :src="require('@/assets/image/xiao/xiao3.png')" alt="" />
            </div>
          </div>

          <div class="posters_text">
            <ul>
              <li>
                <div class="posters_text_left">贊助機構：</div>
                <div class="posters_text_right">曾憲備慈善基金</div>
              </li>
              <li>
                <div class="posters_text_left">主辦機構：</div>
                <div class="posters_text_right">
                  曾憲備慈善基金、新界校長會、中文路（香港）有限公司
                </div>
              </li>
              <li>
                <div class="posters_text_left">合辦機構：</div>
                <div class="posters_text_right">
                  民建聯及郭玲麗立法會議員辦事處、活力慈善基金、梨木樹苗趣社
                </div>
              </li>
              <li>
                <div class="posters_text_left">承辦機構：</div>
                <div class="posters_text_right">中文路（香港）有限公司</div>
              </li>
              <li>
                <div class="posters_text_left">支持機構：</div>
                <div class="posters_text_right">
                  九龍地域校長聯會、香港島校長聯會、文冬漢語普通話中心、北京廣播電視台北京廣播影視培訓中心、朱偉林區議員辦事處、劉松剛區議員辦事處、李漢祥區議員辦事處、邱少雄區議員辦事處、陳孟宜區議員辦事處、林翠玲區議員辦事處、周劍豪區議員辦事處、王淑芬區議員辦事處、李細燕區議員辦事處
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 時間軸 -->
    <div class="TimeAxis">
      <!-- 時間軸上面文字 -->
      <ul>
        <div class="time_xian_top">
          <li
            v-for="(item, index) in tap"
            :key="index"
            @click="stageBut(index)"
          >
            <div
              :class="item.select ? 'select' : 'cshhSelect'"
              v-if="!item.select"
            >
              {{ item.name }}
            </div>
            <img
              :src="require('@/assets/image/qp_2/' + (index + 1) + '.png')"
              alt=""
              v-else
            />
          </li>
        </div>
      </ul>
      <!-- 時間軸 -->
      <ul>
        <div class="time_xian">
          <li
            v-for="(item, index) in tap"
            :key="index"
            @click="stageBut(index)"
          >
            <div class="xian">
              <span
                v-if="item.xuanSelect"
                :class="item.xuanSelect ? 'select' : ''"
                >{{ item.xuanSelect ? '當前階段' : '' }}</span
              >

              <span v-else :class="item.select ? 'span_t' : ''"></span>
            </div>
          </li>
        </div>
      </ul>
      <!-- 時間軸下面文字 -->
      <ul>
        <div class="time_xian_timeBox">
          <li
            v-for="(item, index) in tap"
            :key="index"
            v-html="item.tiem"
            @click="stageBut(index)"
          ></li>
        </div>
      </ul>
      <!-- 時間軸 -->
      <ul>
        <div class="time_xian_fuchuang">
          <li v-for="(item, index) in tap" :key="index">
            <template v-if="item.select">
              <div class="list">
                <h1>{{ item.content.title }}</h1>
                <div class="p_xian" v-html="item.content.value"></div>
                <!-- <div
                  class="butom"
                  v-if="item.xuanSelect && index == 0"
                  @click="aheftBut()"
                >
                  <div class="buttomBut">在線報名</div>
                </div> -->
                <!-- <div class="butom" v-if="item.xuanSelect && index == 0">
                  <div class="buttomBut">報名結束</div>
                </div> -->
              </div>
            </template>
          </li>
        </div>
      </ul>
    </div>

    <!-- 参赛资格 -->
    <!-- <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/cszg.png')" alt="" />
        </div>
        <div class="box_centent cszg2">
          <div class="cszg2_list">
            <ul>
              <li>
                參賽者為 2023-2024
                學年在獲得香港教育局承認的中、小學（含特殊學校）就讀全日制或半日制之學生。
              </li>
              <li>
                參賽者為 2023-2024
                學年在粵港澳大灣區就讀中、小學且持有香港身份證的學生。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 報名確定 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/csxz.png')" alt="" />
        </div>
        <div class="box_centent bxxq">
          <div class="bxxq_title">報名須知</div>
          <div class="bxxq_centent">
            <ul>
              <li>
                中、小學組團體賽各組最多30隊參賽，幼稚園組團體賽最多20隊參賽，名額有限，先到先得。
              </li>
              <li>非牟利機構可根據組別組織學生報名參賽。</li>
              <li>
                各學校/機構最多可報名2隊參賽，每隊人數最少5人，最多30人，參賽隊伍之個別成員只可參與其中一隊的比賽。
              </li>
              <li>
                參賽隊伍之成員允許越級挑戰，以該隊學生的<span style="color:#e80202;">最高年級</span>為標準選擇對應組別，如：A隊有6位參賽成員，其中2位為小學二年級學生，4位為幼稚園學生，須報名小學初級組。
              </li>
              <li>
                參賽隊伍之成員為2024學年在獲得香港教育局承認的中、小學及幼稚園就讀全日制或半日制之學生或在粵港澳大灣區就讀中、小學及幼稚園且持有香港身份證的學生。
              </li>
            </ul>
          </div>
          <div class="bxxq_title">參賽組別</div>
          <div class="tables" style="margin-bottom: 44px;padding: 0 24px">
            <table>
              <tbody>
                <tr>
                  <td>幼稚園</td>
                  <td>幼稚園組（K1 至 K3）</td>
                </tr>
                <tr>
                  <td rowspan="2">小學</td>
                  <td>小學初級組（P1 至 P3）</td>
                </tr>
                <tr>
                  <td>小學高級組（P4 至 P6）</td>
                </tr>
                <tr>
                  <td>中學</td>
                  <td>中學組（S1 至 S6）</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bxxq_title">報名確認</div>
          <div class="bxxq_centent">
            <ul>
              <li>
                當中文路承辦方收妥報名表並審核無誤後，3個工作日內便會向參賽隊伍發出報名成功確認電郵。隊伍聯絡人或指導老師應細閱電郵內的一切注意事項。
              </li>
              <li>
                凡是報名比賽的參賽隊伍，等同於同意主辦方將其參賽影片使用在不同管道媒體進行展示宣傳。
              </li>
            </ul>
          </div>
          <!-- <div class="bxxq_title">注意事項</div>
          <div class="bxxq_centent" style="margin-bottom: 44px">
            <ul>
              <li>
                凡是報名比賽的參賽隊伍，等同於同意主辦方將其參賽影片使用在不同管道媒體進行展示宣傳。
              </li>
            </ul>
          </div> -->
          <div class="bxxq_title">比賽章程</div>
          <div class="bxxq_centent" style="margin-bottom: 8px">
            <ul>
              <li>詳細的「比賽章程」請點擊下方鏈接</li>
            </ul>
            <div class="a_view" @click="zhangcheng()">點擊下載比賽章程</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 比賽详情 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/bsxq.png')" alt="" />
        </div>

        <div class="box_centent bxxq">
          <div class="bxxq_title">比賽誦材</div>
          <div class="bxxq_centent">
            <ul>
              <li>
                比賽官方將提供推薦篇目，以供參賽隊伍選用，誦材篇目選自新詩、散文、古詩等。
              </li>
              <li>
                自選誦材：誦材主題必須符合中華經典美文，例如古典或現代詩詞等，並歡迎各參賽隊伍以不同的創新方式演繹。<br />注：自選誦材內容須依時提交至官方進行審核。
              </li>
              <li>
                初賽、總決賽可選用同一篇誦材，表演總時長應在6分鐘內，最長不超過6分鐘。<br />詳細的「比賽推薦誦材篇目」請點擊下方鏈接
              </li>
            </ul>
            <div class="a_view" @click="socai()">點擊下載比賽誦材</div>
          </div>
          <div class="bxxq_title">評分標準</div>
          <div class="bxxq_centent">
            <div class="tables">
              <table>
                <thead>
                  <tr>
                    <th style="width: 30%">
                      <strong>評判標準</strong>
                    </th>
                    <th style="width: 70%">
                      <strong>內容説明</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="2">
                      <strong>語音（20分）</strong>
                    </td>
                    <td style="text-align: left">
                      <strong
                        >普通話發音（10分）：聲母、韻母、聲調、吐字清晰。每處錯誤扣0.5或1分。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong
                        >節奏韻律（10分）：語速恰當、斷句準確、停連得當。每處錯誤扣0.5或1分。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <strong>合作性（15分）</strong>
                    </td>
                    <td style="text-align: left">
                      <strong>服裝整齊、精神飽滿、舉止從容大方（5分） </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong
                        >聲音整齊度（10分）：領誦、聲部間及合誦等接連流暢自然而有默契。</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <strong>誦材處理（55分）</strong>
                    </td>
                    <td style="text-align: left">
                      <strong
                        >肢體語言（5分）：配以適當的動作和手勢，舒展自然，不過分誇張。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong
                        >感情處理（25分）：富有感情、有感染力、充分表達誦材的情感、抑揚頓挫、添加重音渲染氣氛。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong
                        >表現力（15分）：眼神、表情準確自然、活潑生動，富有張力並符合文意。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong
                        >内容把握（10分）：基調準確，傳達正確主旨，語調或語氣運用配合誦材內容的態度或感情。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3">
                      <strong>創新（10分）</strong>
                    </td>
                    <td style="text-align: left">
                      <strong
                        >聲音變化：聲部組合、停頓、節奏轉換等有出乎意料之外，合乎情理之中的處理方法，聽覺上予人新鮮感。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong
                        >配樂合理：不影響朗誦前提下，配以恰當背景音樂。
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left">
                      <strong>
                        隊型設計：畫面美觀，安排合理，有效暗示或反映誦材的重要內容。
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style="
                line-height: 25px;
                text-align: left;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #e80202;
              "
            >
              *最終評分解釋權為比賽評審團所屬。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/bsjd.png')" alt="" />
        </div>
        <div class="box_centent cszg">
          <div class="cszglis">
            <div class="cszg_title">第一階段：初賽</div>
            <div class="cszg_centent">
              <span style="color: #e80202">初賽</span
              >階段，中、小學及幼稚園各組別隊伍可使用官方推薦篇目或自選誦材進行集誦影片錄製，各參賽隊伍演出時間上限為
              6 分鐘，逾時將會被扣分。演出時間將按提交的影片時間計算。
              <div style="text-indent: 2em">
                所有提交初賽影片的隊伍將獲發電子參賽證書。
              </div>
              <div style="text-indent: 2em">
                各組別分數排名<span style="color: #e80202">前3隊</span
                >將獲入圍現場總決賽資格，若出現同分同名次情況，可同時進入總決賽。
              </div>
            </div>

            <div class="cszg_title">第二階段：總決賽暨頒獎典禮</div>
            <div class="cszg_centent">
              <span style="color: #e80202">總決賽共12隊。總決賽為現場對決，</span
              >各組別隊伍可使用官方推薦篇目或自選誦材進行現場朗誦，演出時間上限為
              6 分鐘，逾時將會被扣分。<span style="color: #e80202"
                >現場將同步錄製對決比賽過程。</span
              >
              <div style="text-indent: 2em">
                總決賽將評出各組別金獎1隊、銀獎1隊、銅獎1隊。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 參賽奬項 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/csjx.png')" alt="" />
        </div>
        <div class="tables">
          <table>
            <thead>
              <tr>
                <th style="width: 18%">
                  <strong>比賽階段</strong>
                </th>
                <th style="width: 18%">
                  <strong>獎項</strong>
                </th>
                <th>
                  <strong>獎品內容</strong>
                </th>
                <th style="width: 30%">
                  <strong>選拔方式 </strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">
                  <strong>初賽階段</strong>
                </td>
                <td>
                  <strong>隊伍參賽證明 </strong>
                </td>
                <td>
                  <strong>電子-參賽證明 </strong>
                </td>
                <td rowspan="2">
                  <strong>提交影片 </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>指導老師感謝狀 </strong>
                </td>
                <td>
                  <strong>電子-感謝狀 </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>隊伍入圍證明 </strong>
                </td>
                <td>
                  <strong>電子-入圍證明 </strong>
                </td>
                <td>
                  <strong
                    >各組別分數排名<br /><span style="color: #e80202"
                      >前3隊</span
                    ><br />入圍決賽
                  </strong>
                </td>
              </tr>
              <tr>
                <td rowspan="3">
                  <strong>總決賽階段 </strong>
                </td>
                <td>
                  <strong>金獎 <br />（1隊） </strong>
                </td>
                <td style="text-align: left">
                  ① 獎金2,000 HKD；<br />② 所屬團體學校/機構獎盃及證書；<br />③
                  金獎優秀指導老師證書；<br />
                  ④ 所屬隊伍之學生參賽證明。
                </td>

                <td rowspan="3">
                  <strong>現場比賽 </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>銀獎 <br />（1隊） </strong>
                </td>
                <td style="text-align: left">
                  ① 獎金1,000 HKD<br />② 所屬團體學校/機構獎盃及證書； <br />③
                  銀獎優秀指導老師證書； <br />④ 所屬隊伍之學生參賽證明。
                </td>
              </tr>
              <tr>
                <td>
                  <strong>銅獎 <br />（1隊） </strong>
                </td>
                <td style="text-align: left">
                  ① 獎金800 HKD
                  <br />② 所屬團體學校/機構獎盃及證書； <br />③
                  銅獎優秀指導老師證書； <br />④ 所屬隊伍之學生參賽證明。
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style="
              margin-top: 20px;
              line-height: 25px;
              text-align: left;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #e80202;
            "
          >
            *主辦方保留對比賽流程安排及比賽相關章程的最終解釋權。如有任何爭議，主辦方保留一切最終決定權。
          </div>
        </div>
      </div>
    </div>
    <!-- 佔位 -->
    <!-- <div class="viewBoxContent"></div> -->

    <!-- <div class="upload-btns">
      <div class="upload_icon">
        <img :src="require('@/assets/image/icon/video_icon.png')" alt="" />
        <div class="icon_text">上載影片</div>
      </div>
      <div class="uploadVideo" @click="handleUploadVideo()">個人</div>
      <div class="uploadVideo" @click="handleToAdmin()">學校</div>
    </div> -->

    <!-- 复赛资料视频上传-->
    <van-popup
      style="margin-top: 30px"
      v-model="datumZZC"
      :close-on-click-overlay="false"
      :closeable="true"
      :round="true"
      :lock-scroll="false"
    >
      <div class="datumBox">
        <div class="titleH1">
          <div class="left_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="title">上載參賽影片</div>
          <div class="right_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="stepBox">
          <div class="stepUls">
            <div
              class="xian"
              :class="stepActive >= 1 ? 'active2' : 'active'"
            ></div>
            <div
              class="xian"
              :class="stepActive >= 2 ? 'active2' : 'active'"
            ></div>
          </div>

          <ul>
            <li
              v-if="chooseFrom == 0"
              v-for="(item, index) in 3"
              :class="stepActive >= index ? 'active' : ''"
              :key="index"
            ></li>
            <li
              v-if="chooseFrom == 1"
              v-for="(item, index) in 2"
              :class="stepActive >= index ? 'active' : ''"
              :key="index"
            ></li>
          </ul>
        </div>
        <div class="stepText">
          <div :class="stepActive >= 0 ? 'active' : ''">登錄</div>
          <div v-if="chooseFrom == 0" :class="stepActive >= 1 ? 'active' : ''">
            完善資料
          </div>
          <div :class="stepActive >= 2 ? 'active' : ''">上載影片</div>
        </div>
        <div class="datumCentent" v-if="stepActive == 0">
          <div class="tab">
            <div class="prompt" style="margin-bottom: 15px; cursor: pointer">
              <div
                :class="chooseFrom == 0 ? 'highlight' : ''"
                @click="datumYZ(0)"
              >
                個人報名
              </div>
              <div
                :class="chooseFrom == 1 ? 'highlight' : ''"
                @click="datumYZ(1)"
              >
                學校報名
              </div>
            </div>
            <template v-if="chooseFrom == 0">
              <div class="row-input">
                <div class="input-name">參賽電話</div>
                <div class="promptInput2">
                  <div class="lis">
                    <div class="lisLife">
                      <!-- <img :src="require('@/assets/image/hk.png')" />
									<span>+852</span> -->
                      <CountryCodeSelector
                        :searchbar="false"
                        :countryCode.sync="prefix"
                        style="width: 100px; margin-right: 15px"
                      ></CountryCodeSelector>
                    </div>
                    <div class="lisRight">
                      <input
                        type="text"
                        placeholder="請輸入參賽電話號碼"
                        value=""
                        v-model.trim="from2.phone"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row-input">
                <div class="input-name">參賽姓名</div>
                <div class="promptInput">
                  <input placeholder="請輸入參賽者姓名" v-model="from2.name" />
                </div></div
            ></template>
            <template v-if="chooseFrom == 1">
              <div class="row-input">
                <div class="input-name">學校編號</div>
                <div class="promptInput">
                  <input
                    placeholder="請輸入學校編號"
                    v-model.trim="from2.schoolCode"
                  />
                </div>
              </div>
              <div class="row-input">
                <div class="input-name">學生編號</div>
                <div class="promptInput">
                  <input
                    placeholder="請輸入學生編號"
                    v-model.trim="from2.studentCode"
                  />
                </div>
              </div>
              <div class="row-input">
                <div class="input-name">學生密碼</div>
                <div class="promptInput">
                  <input
                    placeholder="請輸入學生密碼"
                    v-model.trim="from2.password"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="tabTis_1">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div class="view_guide" @click="generateHeatsZZC = true">
            查看複賽指引
          </div>
          <div
            class="but"
            style="cursor: pointer"
            @click="datumActiveBut ? datumBut(0) : ''"
          >
            下一步
          </div>
        </div>

        <div class="datumCentent" v-if="stepActive == 1">
          <div class="tab">
            <div class="prompt" v-show="schollName == null">就讀學校</div>
            <div
              class="promptInput"
              style="width: 100%; margin-bottom: 15px"
              v-show="schollName == null"
            >
              <input placeholder="請輸入就讀學校" v-model="from2.school_name" />
            </div>
            <div class="prompt">
              證件上傳
              <span>&nbsp;帶照片學生證/身份證明</span>
            </div>
            <div class="promptInput3">
              <uploaderCent
                @uploaderChange="uploaderImageChange"
                accept="image/*"
                uploadText="點擊上傳"
                logoaAtive="0"
              ></uploaderCent>
            </div>
          </div>

          <div class="tabTis">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div class="but" style="cursor: pointer" @click="datumBut(1)">
            下一步
          </div>
        </div>

        <div class="datumCentent" v-if="stepActive == 2">
          <div class="tab">
            <div class="promptInput3">
              <uploaderCent
                @uploaderChange="uploaderVideoChange"
                accept="video/*"
                uploadText="點擊上載影片"
                v-if="wonderful == false"
                logoaAtive="1"
              ></uploaderCent>
              <!-- 上傳影片成功后顯示預覽按鈕 -->
              <div class="videoYulan" v-if="videoYL">
                <div class="guanbu" style="cursor: pointer" @click="videoYLBut">
                  X
                </div>
                <video controls :autoplay="true" :src="videoUrl"></video>
              </div>
              <div
                class="videoYulan2"
                style="cursor: pointer"
                v-if="from2.video != null && wonderful == false"
                @click="videoYLBut"
              >
                <span style="color: #000000 !important; margin-right: 10px"
                  >影片已成功上載</span
                >點擊預覽視頻
              </div>
              <!-- 點擊預覽視頻  @click="videoYLBut"-->
              <!-- <div class="viewVideo" v-if="from2.video && wonderful == false">
                <div>影片已成功上載</div>
                <div
                  class="videoYulan2"
                  @click="videoYLBut"
                >
                  點擊預覽視頻
                </div>
              </div>
              <div v-if="from2.video && wonderful == false" class="reselect">重新选择</div> -->

              <!-- 提交成功 -->
              <div class="wonderful" v-if="wonderful == true">
                <img src="@/assets/image/fusai/ludui.png" />
                恭喜你，成功上載影片，請耐心等待評審結果
              </div>
            </div>
          </div>
          <div class="tabTis" v-if="wonderful == false">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div
            class="but"
            style="cursor: pointer"
            v-if="wonderful == false"
            @click="datumActiveBut ? submit() : ''"
          >
            提交
          </div>
          <!-- <div class="but2" style="cursor: pointer;" @click="getBack()">返回上一步</div> -->
          <div
            class="but"
            style="cursor: pointer"
            v-if="wonderful == true"
            @click="wonderfulBut"
          >
            我知道了
          </div>
        </div>

        <!-- 未入圍/成功入園 -->
        <van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
          <div class="newShortBox">
            <div class="list" v-if="newShortActive == 2">
              <div class="ulst">
                <ul>
                  <li>姓名</li>
                  <li>組別</li>
                  <li>分數</li>
                </ul>
                <ul>
                  <li>
                    {{ userObj.studentName == null ? '' : userObj.studentName }}
                  </li>
                  <li>
                    {{
                      userObj.competitionGroup == null
                        ? ''
                        : userObj.competitionGroup
                    }}
                  </li>
                  <li>{{ userObj.score == null ? '' : userObj.score }}</li>
                </ul>
              </div>
              <div class="lis lisText">
                很遺憾，您的成績未能入圍複賽，非常感謝您的參與
              </div>
            </div>
          </div>
        </van-popup>
      </div>
    </van-popup>
    <van-dialog
      v-model="showGuide"
      :show-close-button="false"
      :close-on-click-overlay="true"
      width="80%"
      z-index="99999"
      confirm-button-text="關 閉"
      @close="closeDialog"
    >
      <div class="guide-container" v-if="showType == 'img'">
        <van-image
          width="100%"
          :src="require('@/assets/image/PC_guide.jpg')"
          fit="contain"
        />
      </div>
      <div class="guide-container" v-if="showType == 'video'">
        <video
          height="99%"
          controls
          :autoplay="true"
          src="https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/4195fb8150d8a227adbfdb6f85d132d0.mp4"
        ></video>
      </div>
    </van-dialog>
    <!-- 複賽指引 -->
    <div class="generateHeats">
      <van-popup v-model="generateHeatsZZC" :round="true" :lock-scroll="false">
        <div class="generateHeatsBox">
          <div class="uls">
            <div class="titleH1">
              <div class="left_icon">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="title">複賽指引</div>
              <div class="right_icon">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div class="textCentent">
              <div class="color1 font_size1" style="margin-bottom: 6px">
                請按照下方參賽影片要求進行錄製，否則將影響評分。如提交影片遇到困難，請于活動頁左下方WhatsApp進行留言，主辦機構中文路ChineseRd將會提供支援。
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                *凡是參賽的選手，等同於同意主辦機構中文路ChineseRd將此次參賽影片使用於全媒體渠道展示宣傳。
                <br />
                *為免遇上網絡擠塞，請預留好上載複賽影片的時間。
                <br />
                *所有參賽影片錄製必須為參賽者本人，如審核發現非參賽者本人錄製之情況，將予以失格公示。
                <br />
              </div>
              <div class="titleRed">【參賽影片要求】</div>
              <div class="color1 font_size1" style="margin-bottom: 6px">
                1. 參賽者應依照以下次序錄製影片：
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                ①
                用普通話進行自我介紹，包含姓名、組別、來自哪一間幼稚園/小學/中學等。
                <br />
                ②
                用普通話讀出比賽誦材之標題及作者（如若誦材未標明作者，可以只讀誦材標題）。
                <br />
                ③ 用普通話朗誦誦材內容一次。
                <br />
                ④ 參賽者必須背誦誦材，違者將只獲評語，沒有評級，亦不獲發獎狀。
                <br />
                ⑤ 如參賽者於影片中重複演繹同一作品，只有首次演繹獲得評審。
                <br />
                ⑥ 請使用豎屏拍攝。
                <br />
                ⑦ 影片的時長要求：幼稚園組、小學組3分鐘以內，中學組5分鐘以內。
                <br />
              </div>
              <div class="color1 font_size1" style="margin-bottom: 6px">
                2. 參賽者必須根據以下指引，否則將影響評分：
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                ① 參賽者錄製參賽影片時請注意着裝得體。
                <br />
                ② 整段影片須清楚顯示參賽者之全身、樣貌及動作。
                <br />
                ③
                拍攝影片時，應保持拍攝鏡頭穩定及定鏡拍攝，不應移動、推進及拉近鏡頭。
                <br />
                ④
                為確保影片聲畫同步、畫面與聲音清晰和流暢，影⽚解像度為720p(1280x720)或以上。
                <br />
                ⑤
                在室內以簡單背景和寧靜的環境下拍攝，並確保光度充足，避免背光、使用虛擬或投射背景。
                <br />
                ⑥ 參賽者不應使用任何款式耳機作為收音用途。
                <br />
                ⑦ 拍攝影片時請勿使用擴音器。
                <br />
              </div>

              <div class="color1 font_size1" style="margin-bottom: 6px">
                3. 參賽者必須跟隨以下指引，否則將被取消資格：
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                ① 影片必須為一鏡到底的拍攝原片，拍攝途中不可暫停攝影機。
                <br />
                ②
                影片中不可加入特別效果，例如濾鏡、過渡、調色、調光、字幕等，亦不得進行任何混音、剪接及後期製作。
                <br />
                ③ 影片必須是現場收音及錄像，不可配音。
                <br />
              </div>
              <div class="titleRed">【影片上傳要求】</div>
              <div class="font_size2" style="margin-bottom: 20px">
                <span class="color1"
                  >① 參賽者須通過本次活動報名系統登錄並上傳參賽影片。</span
                >
                <br />
                <span class="color1"
                  >② 參賽者有責任確保以下資料正確，包括：</span
                >
                <br />
                <span class="color2">
                  網頁中的各項資料正確。如發現資料有誤，請于活動頁左下方WhatsApp進行留言，主辦機構中文路ChineseRd將會提供支援，根據指引於限期內重新提交。
                </span>
                <br />
                <span class="color2"
                  >所提交的影片須與參賽者及其比賽項目相符。如記錄不符，評審團有權不評審相關影片。</span
                >
                <br />
                <span class="color1"
                  >③ 如重複提交，將以最後一次符合提交條件的記錄為準。</span
                >
                <br />
                <span class="color1"
                  >④
                  如評審期內因任何原因，影片未能播放，不會就此作個別通知，參賽者將不獲評分（評級）或獎狀。</span
                >
                <br />
              </div>
              <!-- <div class="but" @click="handleUploadVideo()">
                <img :src="require('@/assets/image/fusai/yunshang.png')" />
                點擊上載視頻
              </div> -->
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </section>
</template>
 
<script>
import Headers from '@/components/header/pc_header_team'
import publics from '@/mixin/public_mixin'
import home from '@/mixin/home_mixin'
import uploaderCent from '@/components/uploaderBox/index.vue'
import CountryCodeSelector from '@/components/CountryCodeSelector'
export default {
  name: 'views',
  data() {
    return {
      // select:true,    選擇
      // xuanSelect:true, 階段
      generateHeatsZZC: false,
      ermUrl: sessionStorage.getItem('whatAppEwm'),
      tap: [
        {
          name: '报名及初賽',
          tiem: `7月1日<br/> 至 <br/>9月30日 22:00`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站',
            value:
              '在官方活動網站提交報名參賽隊伍的總人數、選擇的誦材篇章篇目，並提交初賽影片。'
          }
        },
        {
          name: '公佈總決賽入圍名單',
          tiem: `10月7日`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站公佈入圍隊伍名單',
            value: '請留意電郵或短信提醒並查閱入圍結果。'
          }
        },
        {
          name: '總決賽暨頒獎典禮',
          tiem: `11月2日`,
          select: true,
          xuanSelect: true,
          content: {
            title: '東華三院曾憲備小學（粉嶺龍馬路53號）',
            value: `總決賽：10:00-12:00（9:30 恭候）<br>頒獎典禮：14:00-15:00（13:30 恭候）`
          }
        }
      ],
      datumZZC: false, // 上传视频弹窗控制
      stepActive: 0, //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
      datumActiveBut: true, //防止連續點擊
      wonderful: false, //成功
      videoYL: false, //預覽
      chooseFrom: 0, //驗證
      from2: {
        name: null, //名字
        school_name: null, //学校
        email: null, //邮箱
        phone: null, //手机
        certificate: null, //证件
        video: null, //视频
        schoolCode: null,
        studentCode: null,
        password: null
      },
      schollName: null, //控制学校是否显示
      newShortZZC: false, //入圍 彈窗
      newShortActive: 1, //1是成功  2是失敗
      choose: 0, //查詢的下標
      from: {
        school_name: null,
        email: null,
        phone: null
      },
      finalistsBut: true, //防止連續點擊
      userObj: {
        competitionGroup: null, //組別
        score: null, //分數
        studentName: null //名字
      },
      prefix: '852',
      showGuide: false,
      showType: ''
    }
  },
  computed: {},
  components: {
    Headers,
    uploaderCent,
    CountryCodeSelector
  },
  mixins: [publics, home],
  watch: {},
  created() {},
  mounted() {
    if (this.$route.query.uploadVideo) {
      this.datumZZC = true
      this.$router.replace({ query: { uploadVideo: false } })
    }
  },
  methods: {
    zhangcheng() {
      window.open(this.constitutionUrl_team, '_blank')
      // this.$toast.loading({
      // 	message: '獲取地址中...',
      // 	forbidClick: true,
      // 	duration: 0
      // });

      // let url = 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-07/fe62588d996ef0818389f65f4192086c.pdf';
      // this.$axios({
      //   url: url,
      //   method: 'get',
      //   responseType: 'blob',
      // })
      // .then(response => {
      //   this.$toast.clear();
      //   this.$toast.success('開始下載');
      //   // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   const downloadElement = document.createElement('a');
      //   const href = window.URL.createObjectURL(blob); // 创建下载链接
      //   downloadElement.href = href;
      //   downloadElement.download = 'zhangcheng.pdf'; // 文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); // 下载
      //   document.body.removeChild(downloadElement); // 下载完成后移除元素
      //   window.URL.revokeObjectURL(href); // 释放占用的内存空间
      // }).catch(error => {
      //   this.$toast.clear();
      //   console.error(error); // 处理异常情况
      // });
    },
    closeDialog() {
      const videoElement = document.querySelector('.guide-container video')
      // 暂停视频播放
      videoElement.pause()
    },
    handleShowGuide(type) {
      this.showGuide = true
      this.showType = type
      this.$nextTick(() => {
        // 动态浏览器视口高度
        const viewportHeight = window.innerHeight
        const browserInterfaceHeight = window.outerHeight - window.innerHeight
        const browserWindowHeight = viewportHeight - browserInterfaceHeight
        const imageContainer = document.querySelector('.guide-container')
        imageContainer.style.height = `${browserWindowHeight}px`
      })
    },
    handleUploadVideo() {
      this.datumZZC = true
      this.generateHeatsZZC = false
    },
    handleToAdmin() {
      // 跳管理后台上传视频
      var url = 'https://edu.chineserd.com/personal/entry-list'
      window.open(url, '_blank')
    },
    whatapp() {
      this.home_mdevInit()
      window.open(this.whatUrl, '_blank')
    },
    aheftBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '報名未開始',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      this.$router.push('/signup')
    },
    socai() {
      this.$router.push('/material_team')
    },
    stageBut(e) {
      // console.log(e);
      this.tap.forEach((item, index) => {
        // console.log(item, index);
        if (index == e) {
          item.select = true
        } else {
          item.select = false
        }
      })
    },
    datumYZ(e) {
      this.chooseFrom = e
    },
    lianxi() {
      // 联系我们
      // window.location.href = 'http://wa.me/85291496563'
      window.open('http://wa.me/85291496563')
    },
    // 點擊上傳下一步
    datumBut(e) {
      switch (e) {
        case 0:
          // 下一步 驗證信息
          this.getUser()
          // this.stepActive = 1
          break
        case 1:
          // this.$toast.clear()
          // 判断学校跟证件
          if (this.from2.school_name == null || this.from2.school_name == '') {
            this.$toast('请输入就讀學校 !')
            return
          }

          if (this.from2.certificate == null || this.from2.certificate == '') {
            this.$toast('请上傳證件 !')
            return
          }
          this.stepActive = 2
          break
        case 2:
          // 提交啦
          break
        case 3:
      }
    },
    // 驗證信息
    getUser() {
      let params
      if (this.chooseFrom == 0) {
        params = {
          studentName: this.from2.name,
          prefix: this.prefix,
          phone: this.from2.phone,
          email: null
        }
      } else if (this.chooseFrom == 1) {
        const { schoolCode, studentCode, password } = this.from2
        // 判断三个参数不能为空
        if (schoolCode == '' || schoolCode == null) {
          this.$toast('請輸入學校編號 !')
          return
        }
        if (studentCode == '' || studentCode == null) {
          this.$toast('請輸入學生編號 !')
          return
        }
        if (password == '' || password == null) {
          this.$toast('請輸入學生密碼 !')
          return
        }
        params = {
          schoolCode,
          studentCode,
          password
        }
      }

      if (this.chooseFrom == 0) {
        // 手機
        if (params.phone == '' || params.phone == null) {
          this.$toast('手機號格式有誤 !')
          return
        }

        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }
        if (params.studentName == '' || params.studentName == null) {
          this.$toast('請輸入您的姓名 !')
          return
        }
        // } else if (this.chooseFrom == 1) {
        // 郵箱
        // if (!this.validator_email(params.email)) {
        //   this.$toast('電郵格式有誤 !')
        //   return
        // }
      }
      // console.log('上传:', params);
      this.datumActiveBut = false
      this.$toast.loading()
      this.$axios
        .post(this.$api.getPersonInfo, params)
        .then((response) => {
          this.datumActiveBut = true
          this.$toast.clear()
          // console.log(response);
          const { data, code, msg } = response.data
          if (code == 500) {
            this.$toast.fail(msg)
            return
          }
          const { competitionGroup, score, studentName, shortlisted, school } =
            data
          if (code == 0) {
            this.userObj.competitionGroup = competitionGroup //組別
            this.userObj.score = score //分數
            this.userObj.studentName = studentName //名字
            if (shortlisted) {
              this.from2.school_name = school
              this.schollName = school == '' ? null : school
              // 成功
              if (this.chooseFrom == 0) {
                this.stepActive = 1
              } else {
                this.stepActive = 2
              }
            } else {
              // 失敗
              // this.datumZZC = false; //复赛资料上传弹窗
              this.stepActive = 0 //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 2 //1是成功  2是失敗
            }
          }
        })
        .catch((error) => {
          this.$toast.clear()
          this.datumActiveBut = true
          //请求失败
          this.$toast.fail('error')
          // console.log(error);
        })
    },
    validator_email(val) {
      const email = val.trim()
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim()
      return /^[0-9]*$/.test(phone)
    },
    // 視頻
    uploaderVideoChange(e) {
      // console.log('视频', e);
      this.from2.video = e.vaidoUrl
      this.videoUrl = e.vaidoUrl
      this.$toast.clear()
    },
    // 圖像
    uploaderImageChange(e) {
      // console.log('圖像',e);
      this.from2.certificate = e.vaidoUrl
      this.$toast.clear()
    },
    // 視頻預覽
    videoYLBut() {
      this.videoYL = !this.videoYL
    },
    //返回上一步
    // getBack() {
    // 	this.stepActive--;
    //   this.from2.video = null
    //   this.videoUrl = null
    // },
    // 提交
    submit() {
      const {
        phone,
        name,
        video,
        email,
        certificate,
        school_name,
        schoolCode,
        studentCode,
        password
      } = this.from2
      let params = {
        // videoUrl: 'https://materialv3-1256597606.cos.ap-hongkong.myqc…/dev/2023-09/eb816d9e21e9f18fccea6c461ba2c3dc.mp4', //学生上传的视频内容
        videoUrl: video, //学生上传的视频内容
        // email, //學生電郵
        personalID: certificate,
        currentSchool: school_name
      }
      if (this.chooseFrom == 1) {
        // 学校报名
        params.schoolCode = schoolCode
        params.studentCode = studentCode
        params.password = password
      } else {
        // 个人报名
        params.phone = phone
        params.studentName = name
      }

      if (params.videoUrl == null || params.videoUrl == '') {
        this.$toast('請上傳參賽作品 !')
        return
      }

      this.datumActiveBut = false
      this.$toast.loading({
        message: '正在提交中...',
        duration: 0,
        forbidClick: true
      })

      // 本地測試專用
      // this.$axios({
      // 	url: '/api/statistics/competition/update',
      // 	method: 'post',
      // 	data: params
      // })

      // 正式發包
      this.$axios
        .post(this.$api.update, params)
        .then((response) => {
          this.datumActiveBut = true
          // console.log(response);
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg)
          }
          if (response.data.code == 0) {
            this.$toast.clear()
            this.wonderful = true
          }
        })
        .catch((error) => {
          this.datumActiveBut = true
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    // 上傳成功  相當於初始化
    wonderfulBut() {
      this.wonderful = false
      this.datumZZC = false
      this.stepActive = 0
      let from = {
        name: null,
        school_name: null,
        email: null,
        phone: null,
        certificate: null,
        video: null
      }
      this.from2 = from
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;
  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      // width: 1024px;
      left: 0;
      right: 15%;
      bottom: 0;
      top: 0%;
      margin: auto;
      display: flex;
      align-items: flex-start;
      justify-content: right;

      .ewm {
        width: 141px;
        min-height: 184px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 6px;
        box-sizing: border-box;
        cursor: pointer;

        .ewm2 {
          width: 100%;
          height: 100%;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;
          padding-top: 4px;
          .ewm3 {
            margin: 0 auto;
            width: 122px;
            height: 122px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: url('~@/assets/image/icon/ewmbut.png') no-repeat;
            background-size: 100% 100%;
            box-shadow: inset 0px 1px 4px 0px rgba(234, 138, 7, 0.48),
              inset 0px -5px 3px 0px rgba(255, 227, 129, 0.57);
            vertical-align: middle; /* 将文本和图片垂直居中对齐 */

            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
              vertical-align: middle; /* 将图片和文本垂直居中对齐 */
            }
          }
        }
      }
    }
  }

  .organizers {
    max-width: 1240px;
    margin: auto;
    margin-bottom: 54px;
    margin-top: 30px;
    .box_uls {
      width: 964px;
      min-height: 100px;
      margin: auto;
      box-sizing: border-box;
      border-image-source: url('~@/assets/image/hodgepodge/pcbeij.png');
      border-image-slice: 84 10 10 10;
      border-image-width: 67px 9px 9px 9px;
      border-width: 9px;
      padding: 0 10px 10px 10px;
      box-sizing: border-box;

      .box_title {
        width: 100%;
        height: 25px;
        box-sizing: border-box;
        margin: auto;
        position: relative;

        img {
          // width: 100%;
          height: 118.8px;
          margin-top: -6.1%;
          // object-fit: none;
        }
      }
      .box_centent {
        position: relative;
        width: 100%;
        background: #ffffff;
        border-radius: 12px;
      }

      .zhuban {
        padding: 21px 100px 8px 130px;
        box-sizing: border-box;

        .xiaoIconBox {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 27px;

          .xiaoIconList {
            width: 153px;
            height: 64px;
            background: #ffffff;
            border-radius: 12px 12px 12px 12px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .posters_text {
          width: 100%;

          ul {
            width: 100%;

            li {
              width: 100%;
              margin-bottom: 16px;
              display: flex;

              .posters_text_left {
                width: 80px;
                font-size: 14px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 24px;
              }

              .posters_text_right {
                width: calc(100% - 80px);
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  .TimeAxis {
    max-width: 1240px;
    // height: 800px;
    margin: auto;

    ul {
      width: 100%;

      .time_xian {
        width: 100%;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 22%,
          #ffffff 80%,
          rgba(255, 255, 255, 0) 100%
        );
        display: flex;
        justify-content: space-evenly;

        li {
          width: 167px;
          display: flex;
          align-items: center;

          .xian {
            width: 100%;
            height: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              width: 16px;
              height: 16px;
              background: #ffffff;
              border: 2px solid #ffffff;
              border-radius: 50px;
              cursor: pointer;
            }

            .span_t {
              width: 20px !important;
              height: 20px !important;
              background: #ffe600 !important;
            }

            .select {
              width: auto !important;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              font-size: 14px;
              text-align: center;
              box-sizing: border-box;
              background: #f78040;
              border-radius: 50px;
              border: 1px solid #fff;
              color: #fff;
            }
          }
        }
      }

      .time_xian_top {
        width: 100%;
        height: 86px;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 17px;

        li {
          width: 167px;
          display: flex;
          align-items: center;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          cursor: pointer;
        }

        .select {
          // border-image-source: url("~@/assets/image/icon/qipao.png");
          // border-image-slice: 84 10 10 10;
          // border-image-width: 33px 9px 0px 10px;
          // border-width: 9px;
          // padding: 7px 17px 14px 17px;
          // box-sizing: border-box;
          // font-size: 16px;
          // font-family: PingFang SC-Semibold, PingFang SC;
          // font-weight: 600;
          // color: #903300;
          // line-height: 16px;
        }

        .cshhSelect {
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          // font-weight: 600;
          color: #ffffff;
          line-height: 19px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .time_xian_timeBox {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-top: 17px;

        li {
          width: 167px;
          display: flex;
          align-items: center;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          font-size: 14px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 22px;
          cursor: pointer;
        }
      }

      .time_xian_fuchuang {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        li {
          width: 167px;
          min-height: 136px;

          .list {
            width: 343px;
            min-height: 136px;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
            padding: 12px;
            box-sizing: border-box;
            margin-top: 38px;
            border-radius: 16px;
            background: #ffffff;
            position: relative;

            h1 {
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #f97226;
              line-height: 20px;
              text-align: center;
              margin-bottom: 4px;
              text-align: left;
            }

            .p_xian {
              width: 100%;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              text-align: left;
            }

            .butom {
              width: 100%;
              display: flex;
              justify-content: right;
              position: absolute;
              bottom: 0;
              padding: 0 24px 12px 0;
              box-sizing: border-box;

              .buttomBut {
                padding: 10px 12px;
                box-sizing: border-box;
                border-radius: 16px;
                font-size: 12px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 14px;
                background: url('~@/assets/image/hodgepodge/buttom.png')
                  no-repeat;
                background-size: 100% 100%;
                // background: #d0d0d0;
                cursor: pointer;
              }
            }
          }
        }

        li:last-child {
          .list {
            float: right;
          }
        }
      }
    }
  }

  .viewBoxContent {
    max-width: 1240px;
    margin: auto;
    margin-top: 104px;

    .box_uls {
      width: 964px;
      // min-height: 314px;
      margin: auto;
      box-sizing: border-box;
      border-image-source: url('~@/assets/image/hodgepodge/pcbeij.png');
      border-image-slice: 84 10 10 10;
      border-image-width: 67px 9px 9px 9px;
      border-width: 9px;
      padding: 0 10px 10px 10px;
      box-sizing: border-box;

      .box_title {
        width: 100%;
        height: 25px;
        box-sizing: border-box;
        margin: auto;
        position: relative;

        img {
          // width: 100%;
          height: 118.8px;
          margin-top: -6.1%;
          // object-fit: none;
        }
      }
      .box_centent {
        position: relative;
        width: 100%;
        background: #ffffff;
        border-radius: 12px;
        padding: 24px 130px;
        box-sizing: border-box;
      }

      .cszg {
        width: 100%;

        .cszglis {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .cszg_title {
            min-width: 160px;
            height: 36px;
            background: #ff9003;
            box-shadow: 0px 0px 7px 0px rgba(249, 118, 47, 0.3);
            border-radius: 31px 31px 31px 31px;
            border: 2px solid #ffffff;
            padding: 7px 48px;
            box-sizing: border-box;
            font-size: 18px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            margin: auto;
            margin-bottom: 16px;
          }

          .cszg_centent {
            text-indent: 2em;
            width: 100%;
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            color: #666666;
            line-height: 24px;
            text-align: left;
            margin-bottom: 44px;

            span {
              color: #f97226;
              font-weight: 600;
            }
          }

          .cszg_centent:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .cszg2 {
        .cszg2_list {
          // font-size: 15px;
          // font-family: PingFang SC-Semibold, PingFang SC;
          // font-weight: 600;
          // // color: #f97226;
          // line-height: 22px;
          // text-align: left;
          // margin-bottom: 24px;
          ul {
            width: 100%;
            padding-left: 0.48rem;
            box-sizing: border-box;

            li {
              width: 100%;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              color: #666666;
              line-height: 28px;
              text-align: left;
              list-style: disc;
            }
          }
        }
      }

      .bxxq {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .bxxq_title {
          min-width: 160px;
          height: 36px;
          background: #ff9003;
          box-shadow: 0px 0px 7px 0px rgba(249, 118, 47, 0.3);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          padding: 7px 48px;
          box-sizing: border-box;
          font-size: 18px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin: auto;
          margin-bottom: 16px;
        }

        .bxxq_centent {
          width: 100%;
          margin-bottom: 44px;

          ul {
            width: 100%;
            padding-left: 0.48rem;
            box-sizing: border-box;

            li {
              width: 100%;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              color: #666666;
              line-height: 28px;
              text-align: left;
              list-style: disc;

              span {
                color: #f97226;
                font-weight: 600;
              }

              font {
                color: #e80202;
                font-weight: 600;
              }
            }
          }
        }

        .bxxq_centent:last-child {
          margin-bottom: 0px;
        }
      }

      .csjx {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .csjx_title {
          min-width: 160px;
          height: 36px;
          background: #ff9003;
          box-shadow: 0px 0px 7px 0px rgba(249, 118, 47, 0.3);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          padding: 7px 48px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin: auto;
          margin-bottom: 24px;
        }

        // .csjx_centent:last-child {
        //   margin-bottom: 0px;
        // }

        .csjx_centent {
          width: 100%;
          margin-bottom: 44px;

          .csjx_centent_view1 {
            width: 414px;
            min-height: 141px;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #fff6f1;
            border-radius: 16px;
            border: 2px solid #fed011;
            position: relative;
            margin: auto;
            margin-bottom: 24px;

            h1 {
              width: 100%;
              display: flex;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #f97226;
              line-height: 18px;

              span {
                color: #ff8f03;
                margin-left: 8px;
                font-weight: 500;
                font-size: 14px;
              }
            }

            ul {
              width: 100%;
              margin-top: 20px;
              padding-left: 20px;
              box-sizing: border-box;

              li {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 14px;
                text-align: left;
                list-style: disc;
                font-weight: 400;
                margin-bottom: 15px;
              }

              li:last-child {
                margin-bottom: 0px;
              }
            }

            .jinpai {
              width: 90px;
              position: absolute;
              right: -37px;
              bottom: -6px;

              img {
                width: 100%;
              }
            }

            .jiangbei {
              width: 90px;
              position: absolute;
              right: -40px;
              bottom: -6px;

              img {
                width: 100%;
              }
            }
          }

          .csjx_centent_view2 {
            width: 414px;
            background: #fff6f1;
            border-radius: 16px;
            border: 1px solid #fa772f;
            position: relative;
            margin: auto;
            // margin-bottom: 24px;
            display: flex;

            .lists {
              width: 146px;
              height: 98px;
              font-size: 14px;
              font-family: PingFang SC-Semibold, PingFang SC;
              color: #f97226;
              line-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #fa772f;
            }

            .lists2 {
              width: calc(100% - 146px);

              ul {
                width: 100%;
                height: 100%;
                padding: 16px 24px 16px 48px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;

                li {
                  width: 100%;
                  font-size: 14px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  text-align: left;
                  list-style: disc;
                  font-weight: 400;
                  margin-bottom: 16px;
                }

                li:last-child {
                  margin-bottom: 0px;
                }
              }
            }
          }

          .bo_color {
            background: #fffce8 !important;
            border: 2px solid #fed011 !important;
          }

          .bo_color2 {
            background: #f9fcff !important;
            border: 2px solid #d1e1ee !important;
          }
          .bo_color3 {
            background: #fff4ee !important;
            border: 2px solid #fa772f !important;
          }
        }
      }
    }
  }
  .upload-btns {
    position: absolute;
    z-index: 999999;
    top: 16px;
    right: 20px;
    display: flex;
    align-items: center;
    .upload_icon {
      position: relative;
      img {
        height: 46px;
        width: 90px;
      }
      .icon_text {
        width: 90px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-56%, -50%);
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .uploadVideo {
    cursor: pointer;
    width: 120px;
    height: 46px;
    background: url('~@/assets/image/icon/but.png') no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 15px;
    box-shadow: 0px 4px 9px 0px rgba(244, 86, 5, 0.4);
    border-radius: 80px;
    padding: 15px;
    box-sizing: border-box;
    margin-left: 10px;
  }

  .datumBox {
    width: 780px;
    // height: 308px;
    padding-top: 45px;
    padding-bottom: 45px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;

    .titleH1 {
      width: 100%;
      font-size: 22px;
      color: #ff5a25;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .right_icon {
        display: flex;
        align-items: center;
        div:nth-child(3) {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:first-child {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
      }

      .left_icon {
        display: flex;
        align-items: center;
        div:first-child {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(3) {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
      }
    }

    .stepBox {
      width: 55%;
      margin: auto;
      position: relative;

      .stepUls {
        width: 100%;
        height: 30px;
        display: flex;

        .xian {
          width: 50%;
          height: 100%;
          position: relative;
        }

        .active {
          position: relative;
        }

        .active:after {
          content: ' ';
          width: 100%;
          height: 2.5px;
          background: #f5f5f5;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        .active2:after {
          content: ' ';
          width: 100%;
          height: 2.5px;
          background: #ff5a25;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      ul {
        width: 100%;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #ff5a25;
          position: relative;

          div {
            position: absolute;
            bottom: -10%;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #cccccc;
          }
        }

        .active {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: url('~@/assets/image/fusai/dui.png') no-repeat;
          background-size: 100%;
        }
      }
    }

    .stepText {
      width: 62%;
      margin: auto;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
      }
      div:nth-child(1) {
        margin-left: 5%;
      }

      div:nth-child(2) {
        margin-left: 13%;
      }

      div:nth-child(3) {
        margin-left: 10%;
      }

      .active {
        color: #de2810 !important;
      }
    }

    .datumCentent {
      width: 70%;
      margin: auto;
      margin-top: 30px;

      .tab {
        width: 100%;
        text-align: left;
        margin-bottom: 13px;

        .prompt {
          height: 26px;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 18px;
          // margin-bottom: 12px;
          // padding-left: 13px;
          // box-sizing: border-box;
          display: flex;

          div {
            margin-right: 18px;
            text-align: center;
            line-height: 20px;
            color: #999999;
          }

          .highlight {
            color: #4d4d4d !important;
            position: relative;
          }

          .highlight:after {
            content: ' ';
            width: 90%;
            height: 1px;
            background: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          span {
            font-size: 9.8px !important;
            color: #de2810;
            margin-left: 5px;
          }
        }

        .lis {
          width: 100%;
          height: 45px;
          line-height: 45px;
          background: #f5f5f5;
          display: flex;
          justify-content: space-between;
          border-radius: 6px 6px 6px 6px;
          // overflow: hidden;
          position: relative;
          .lisLife {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4a4a4a;
            box-sizing: border-box;
            width: 18%;
            padding-left: 15px;

            img {
              width: 16px;
              height: 11px;
              margin-right: 5px;
            }
          }

          .lisRight {
            width: 81%;
            height: 100%;
            input {
              width: 100%;
              height: 100%;
              padding-left: 13px;
              box-sizing: border-box;
              border: none;
              background: #f5f5f5;
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
            }
            input::-webkit-input-placeholder {
              color: #999999;
            }
            /* 使用webkit内核的浏览器 */
            input:-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本4-18 */
            input::-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本19+ */
            input:-ms-input-placeholder {
              color: #999999;
            } /* IE浏览器 */
          }
        }
        .row-input {
          display: flex;
          align-items: center;
          font-size: 0px;
          margin-bottom: 15px;
        }
        .input-name {
          width: 20%;
          // height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          text-align: right;
          padding-right: 15px;
          box-sizing: border-box;
        }
        .promptInput {
          width: 80%;
          height: 50px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 5px;
          // margin-bottom: 15px;

          input {
            width: 100%;
            height: 100%;
            font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #f5f5f5;
            border-radius: 5px;
            border: none;
            padding-left: 13px;
            box-sizing: border-box;
          }

          input::-webkit-input-placeholder {
            color: #999999;
          }
          /* 使用webkit内核的浏览器 */
          input:-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本4-18 */
          input::-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本19+ */
          input:-ms-input-placeholder {
            color: #999999;
          } /* IE浏览器 */
        }

        .promptInput2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80%;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 5px;
          // margin-bottom: 15px;

          span {
            width: 100%;
            height: 100%;
          }
          .span_tw {
            color: rgb(153, 153, 153) !important;
          }
          .promptInputValue {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            border-radius: 6px 6px 6px 6px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }

        .promptInput3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #f7f8fa;
          height: 210px;
          border: 3px dotted #f5f5f5;
          position: relative;

          .wonderful {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4d4d4d;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50px;
              margin-bottom: 30px;
            }
          }

          .videoYulan {
            width: 100%;
            height: 100%;
            z-index: 999999;
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            video {
              width: 80%;
              // object-fit: cover;
              height: 100%;
            }

            .guanbu {
              position: absolute;
              top: 5%;
              right: 5%;
              width: 20px;
              height: 20px;
              color: #fff;
              font-size: 12px;
              z-index: 999;
              border-radius: 10px;
              border: 1px solid #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .videoYulan2 {
            // cursor: pointer;
            // color: #ff5a25;
            // margin-left: 10px;
            position: absolute;
            bottom: 8px;
            right: 0px;
            left: 0px;
            margin: auto;
            font-size: 12px;
            color: #ff5a25;
            text-align: center;
            width: 100%;
          }
        }
      }

      .tabTis {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 20px;
        margin-bottom: 50px;

        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
      .tabTis_1 {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 20px;
        // margin-bottom: 50px;

        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
      .view_guide {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff5a25;
        margin-top: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
      .but {
        width: 140px;
        height: 40px;
        background: url('~@/assets/image/but.png') no-repeat;
        background-size: 100%;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        margin: auto;
        margin-top: 20px;
      }

      .but2 {
        width: 140px;
        height: 30px;
        text-align: center;
        font-size: 14px;
        color: #4d4d4d;
        margin: auto;
        margin-top: 10px;
      }
    }
  }
}

/deep/ .CountryCodeSelectorContainer {
  width: 100px;
  .arrow-down {
    width: 12px;
    height: 12px;
  }
}
.viewVideo {
  position: absolute;
  bottom: 30px;
  font-size: 12px;
  color: #4d4d4d;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.reselect {
  position: absolute;
  bottom: 5px;
  font-size: 12px;
  margin: 0 auto;
  color: rgb(235, 196, 120);
  cursor: pointer;
}
.newShortBox {
  width: 600px;
  height: 308px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .lis {
      width: 100%;
      margin-bottom: 20px;
    }

    .lis:last-child {
      margin-bottom: 0px !important;
    }

    .lisCenten {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lisText2 {
      display: flex;
      justify-content: space-between;
      width: 65%;
      margin: auto;
      font-size: 14px;

      span {
        color: #ff5a25;
      }
    }

    .ulst {
      width: 70%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px 3px 3px 3px;

        li {
          width: 33.3%;
          text-align: center;
          font-size: 14px;
          padding: 0 5px;
        }
        li:nth-child(2) {
          width: 30% !important;
        }
      }

      ul:nth-child(1) {
        background: #ff5a25;
        height: 30px;
        line-height: 30px;

        li {
          height: 100%;
          color: #fff;
        }
      }

      ul:nth-child(2) {
        background: #feffeb;
        font-size: 9.8px !important;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 20px;

        li {
          color: #4e4e4e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lisText {
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 24px;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url('~@/assets/image/but.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }
  }
}
.show-link {
  color: #f97226;
  font-weight: 400 !important;
}
.show-link:hover {
  font-weight: 600 !important;
  text-decoration: underline;
  cursor: pointer;
}

.guide-container {
  padding: 16px;
  min-height: 600px;
  overflow-y: auto;
}
.van-dialog {
  top: 50%;
  background-color: #000;
}
.generateHeatsBox {
  width: 960px;
  padding: 12px;
  box-sizing: border-box;
  background: #ffd123;
  border-radius: 16px 16px 16px 16px;
  .uls {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background: #fff;

    .titleH1 {
      width: 100%;
      font-size: 24px;
      color: #ff5a25;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0 15px 0;

      .right_icon {
        display: flex;
        align-items: center;
        div:nth-child(3) {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:first-child {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
      }

      .left_icon {
        display: flex;
        align-items: center;
        div:first-child {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(3) {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
      }
    }

    .textCentent {
      width: 100%;
      text-align: left;
      line-height: 26px;
      font-family: PingFang SC-Medium, PingFang SC;

      .color1 {
        color: #ff5a25;
      }
      .color2 {
        color: #999999;
      }

      .font_size1 {
        font-size: 18px;
      }
      .font_size2 {
        font-size: 14px;
      }

      .titleRed {
        width: 180px;
        height: 40px;
        line-height: 40px;
        background: #ff5a25;
        border-radius: 3px 3px 3px 3px;
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 12px;
      }

      .but {
        width: 180px;
        height: 40px;
        line-height: 40px;
        background: #ff7e18;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        border-radius: 3px 3px 3px 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin: auto;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
  }
}
.generateHeats {
  .van-popup {
    z-index: 999999 !important;
  }
}
.tables {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0px;
    border: 1px solid #fa772f;
    font-size: 16px;
    line-height: 22px;
    font-family: PingFang SC-Semibold, PingFang SC;
    color: #666666;
    th {
      border: 1px solid #fa772f;
      padding: 10px;
      font-weight: 600;
    }
    td {
      border: 1px solid #fa772f;
      padding: 10px;
      vertical-align: middle;
    }
  }
}
.a_view {
  margin: 20px auto 0 auto;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #e80202;
  text-decoration: underline;
  cursor: pointer;
}
</style>